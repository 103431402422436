import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  proposalData: [],
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
};

const getters = {
  getAllProposalData: (state) => state.property_proposalss,
};

const mutations = {
  setAllProposalData: (state, proposal) =>
    (state.proposalData = proposal),
  doNothing: (state) => state,
  addToProposalData: (state, proposal) =>
    state.proposalData.unshift(proposal),
  updateProposalData: (state, updatedData) => {
    state.proposalData.forEach((propData) => {
      if (propData.id == updatedData.id) {
        propData.name = updatedData.name;
      }
    });
  },
  removeProposalData: (state, id) => {
    state.proposalData.splice(id, 1);
  },
};

const actions = {
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All property_proposalss
   * @param {Number} data - property_proposalss Object
   * @returns Promise
   */
  async fetchAllProposalData({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.proposal.getAllProposals+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllProposalData", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

 
  /**
   * @description Create New property_proposals
   * @param {Object} data - property_proposals Object
   * @returns Promise
   */
  createProposalData({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.proposal.createProposal, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToProposalData", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update property_proposals Info
   * @param {Object} data - Update property_proposals Object
   * @returns Promise
   */
  updateProposalDataById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.proposal.updateProposal+`${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("updateProposalData", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Remove property_proposals
   * @param {Number} id - property_proposals Id
   * @returns Promise
   */
  async removeProposalDataById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.proposal.removeProposal + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removeProposalData", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
