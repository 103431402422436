import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false, city: "",
      state: "",
      pincode: "",
      country: ""
    };
};

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  filterValue: loadFilterValue(),
  broker: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getFilterValue: (state) => {
    return state.filterValue;
  },
  getAllBroker: (state) => state.broker,
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllBroker: (state, payload) => (state.broker = payload),
  setAllBrokerProperties: (state, payload) => (state.broker = payload),
  addToBroker: (state, payload) => state.broker.unshift(payload),
  updateToBroker: (state, updatedData) => {
    state.broker.forEach((user) => {
      if (user.id == updatedData.id) {
        user.full_name = updatedData.full_name;
        user.email = updatedData.email;
        user.mobile = updatedData.mobile;
        user.street = updatedData.street;
        user.city = updatedData.city;
        user.state = updatedData.state;
        user.country = updatedData.country;
        user.pincode = updatedData.pincode;
        user.disabled = updatedData.disabled;
        user.visible = updatedData.visible;
        user.image = updatedData.image;
      }
    });
  },
  removeBroker: (state, id) => {
    state.broker = state.broker.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All User
   * @param {Number} data - broker Object
   * @returns Promise
   */

  async fetchAllBroker({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy?.length ? sortBy[0] : "createdAt";
        let sort_order = sortDesc?.length > 0 && sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokers.getAllBrokers + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBroker", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
    * @description Get All Broker Properties
    * @param {Number} data - broker Object
    * @returns Promise
    */

  async fetchAllBrokerProperties({ commit }, id) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (id.options.all) {
        params = id.options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = id.options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokers.getAllBrokerProperties+`${id.id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBroker", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update User Profile
   * @param {Object} data - Update Profile Data Object
   * @returns Promise
   */
  updateBroker({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.brokers.updateBroker+`${data._id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToBroker", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search User
   * @param {Number} data - User Object
   * @returns Promise
   */

  searchBroker({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokers.searchBrokers + `?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllBroker", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove Broker
   * @param {Object} data - Remove Broker
   * @returns Promise
   */
  removeBrokerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.brokers.removeBroker+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeBroker", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  

  /**
   * @description Get User Info by ID
   * @param {Number} id - User ID
   * @returns Promise
   */
  fetchBrokerByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.brokers.getBrokerByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New User by SUPER ADMIN
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createBroker({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.brokers.createBroker, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToBroker", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },


  /**
* @description Filter broker
* @param {Number} id - pincode , state, city, country
* @returns Promise
*/
  async filterBroker({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          country: state.filterValue.country,

        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          country: state.filterValue.country,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokers.filterBrokers + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBroker", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
