import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  brokerRequirementProperty: [],
};

const getters = {
  getBrokerProperty: (state) => state.brokerRequirementProperty,
};

const mutations = {
  setAllBrokerProperty: (state, payload) => (state.brokerRequirementProperty = payload),
  doNothing: (state) => state,
  updateBrokerProperties: (state, updatedData) => {
    state.brokerRequirementProperty.forEach((brokerRequirement) => {
        if (brokerRequirement.id == updatedData.id) {
            // Update Data
            brokerRequirement.agp_status = updatedData.agp_status;
        }
    });
},
};

const actions = {
  /**
   * @description Get All external property
   * @param {Number} data - external property Object
   * @returns Promise
   */
  async fetchAllBrokerRequirementForProperties({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokerProperties.getAllBrokerProperty+ `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrokerProperty", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get all Broker properties
   * @param {Object} data - Broker properties Id
   * @returns Promise
   */
  async fetchAllBrokerPropertyByRequirementId({ commit }, options) {
    console.log(options, 'options');
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokerProperties.getAllBrokerPropertiesByRequirementId+`${options.id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrokerProperty", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async updateBrokerPropertiesStatus({ commit }, data) {
    console.log(data, 'data');
    return new Promise((resolve, reject) => {
        axios
            .put(apiPath.brokerProperties.updateStatus+`${data.id}/agp_status`, data)
            .then(async (res) => {
                if (res.data.success) {
                  console.log("Hi");
                    await commit("doNothing");
                    resolve(res);
                }
            })
            .catch((e) => {
                reject(e);
            });
    });
},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
