// import router from '@/router';
import store from "@/store";
import axios from "axios";
import { app_types } from "./constants/role";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_URL,
    withCredentials: true,
});

// Request interceptor for authentication
axiosInstance.interceptors.request.use(
    (req) => {
        // Add your authentication logic here
        const token = localStorage.getItem("accessToken");
        if (token) {
            req.headers.Authorization = token; // Use "Authorization"
        }
        req.headers["appType"] = app_types.Internal;
        return req;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// let isRefresh = false;
// let subscribers = [];

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            // config,
            response: { status, data },
        } = error;
        // const originalRequest = config;

        if (
            status == 401 && 
            (
                data.message == "USER BLOCKED" ||
                data.message == "INVALID REFRESH TOKEN" ||
                data.message == "REFRESH TOKEN NOT FOUND" ||
                data.message == "REFRESH TOKEN EXPIRED" ||
                data.message == "NOT LOGGED IN" || 
                data.message == "EXPIRED" ||
                data.message == "INVALID TOKEN" ||
                data.message == "NO SESSION" ||
                data.message == "UNAUTHORIZED"
            )
        ) {
            // subscribers = [];
            store.dispatch("authStore/clearUserSession");
            return Promise.reject(data.message);
        }

        /** REFRESH TOKEN LOGIC

        // if (
        //   status == 401 &&
        //   (data.message == "EXPIRED" ||
        //     data.message == "INVALID TOKEN" ||
        //     data.message == "NO SESSION")
        // ) {
        //     const retryOrigReq = new Promise((resolve) => {
        //         subscribeTokenRefresh((token) => {
        //             // replace the expired token and retry
        //             originalRequest.headers["Authorization"] = token;
        //             resolve(axios(originalRequest));
        //         });
        //     });
        //     if (!isRefresh) {
        //         isRefresh = true;
        //         await store.dispatch("authStore/refreshToken").then((data) => {
        //             isRefresh = false;
        //             onRrefreshed(data.data.accessToken);
        //             localStorage.setItem("accessToken", data.data.accessToken);

        //             subscribers = [];
        //         });
        //         return retryOrigReq;
        //     }
        // } 

        */
        
        else if(status == 401 && data.type == "account_status") {
            store.commit("setSnackbarMessage", data.message);
            store.commit("toggleSnackbar");
            store.dispatch("authStore/clearUserSession");
            return Promise.reject(data.message);
        } else if(status == 403 && data.type === 'account_status') {
            // TODO: This check can moved at a store level if needed at multiple places.
            if(store.state?.snackbar?.isSnackBarVisible) {
                store.commit("toggleSnackbar");
            }
            store.commit("setSnackbarMessage", data.message);
            store.commit("toggleSnackbar");
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
// function subscribeTokenRefresh(cb) {
//     subscribers.push(cb);
// }

// function onRrefreshed(token) {
//     subscribers.map((cb) => cb(token));
// }

// subscribers = [];

export default axiosInstance;
