import router from "@/router";
import axiosInstance from "@/utils/axios";
import { apiPath } from "@/config/apiPath";
import { app_types } from "@/utils/constants/role";

const state = {
  currentUser: null,
  accessToken: null,
  refreshToken: null,
  userLoggedIn: false,
  currentPermission: null,
};

const getters = {
  getUser: (state) => state.currentUser,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
};

const mutations = {
  setUser: (state, payload) => {
    state.currentUser = payload;
    state.currentPermission = payload.permissions;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },
  setAccessToken: (state, payload) => {
    state.accessToken = payload;
    localStorage.setItem("accessToken", payload);
  },
  changeUserState: (state, payload) => (state.userLoggedIn = payload),
  clearAll: (state) => {
    state.currentUser = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.userLoggedIn = false;
    localStorage.clear();
  },
  doNothing: (state) => state,
};

const actions = {
  login({ commit }, payload) {
    payload = {
      ...payload,
      appType: app_types.Internal,
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.login, payload)
        .then((res) => {
          if (res.status == 200) {
            // redirect to the home page
            if (res.data.user.app_type == "Internal") {
              commit("setUser", res.data.user);
              commit("setAccessToken", res.data.accessToken);
              commit("changeUserState", true);

              // localStorage.setItem("accessToken", res.data.accessToken);
              // localStorage.setItem("userInfo", JSON.stringify(res.data.user));
              resolve(res.data);
              router.push("/");
            } else {
              commit("clearAll");
              if (window.location.pathname != "/login") {
                return router.push({ name: "login" });
              }
              reject({
                success: false,
                message: "Account does not exist",
              });
            }
          }
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  refreshToken({ commit }) {
    return new Promise((resovle, reject) => {
      axiosInstance
        .get(apiPath.auth.refreshToken, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status == 200) {
            commit("setAccessToken", res.data.accessToken);
            localStorage.setItem("accessToken", res.data.accessToken);
            resovle(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // description: Get current User Data
  getCurrentUserData({ commit }) {
    return new Promise((resovle, reject) => {
      axiosInstance
        .get(apiPath.auth.getCurrentUser)
        .then((res) => {
          if (res.status == 200) {
            commit("doNothing");
            resovle(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  logout({ dispatch }) {
    return new Promise((resovle, reject) => {
      axiosInstance
        .get(apiPath.auth.logout)
        .then((res) => {
          
          resovle(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          dispatch('clearUserSession');
        });
    });
  },

  clearUserSession({ commit }) {
    commit("clearAll");
    document.cookie =
      "refreshToken; expires=Monday, 01 Jan 1970 00:00:00 UTC;";
    if(router.currentRoute?.name !== 'login') {
      return router.push({ name: "login" });  
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
