import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      requireAuth: true,
      title: 'Home'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "LoginPage" */ '../views/LoginPage.vue'),
    meta: {
      requireAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: () => import('@/views/ForgotPasswordPage.vue'),
    meta: {
      requireAuth: false,
      title: 'Forgot Password'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "ProfilePage" */ '../views/ProfilePage.vue'),
    meta: {
      requireAuth: true,
      title: 'Profile'
    }
  },
  {
    path: '/landlords',
    name: 'landlords',
    component: () => import(/* webpackChunkName: "LandlordsPage" */ '@/views/Property Assets Pages/LandlordsPage.vue'),
    meta: {
      requireAuth: true,
      title: 'Landlords'
    }
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import(/* webpackChunkName: "TagsPage" */ '@/views/Configuration Pages/TagsPage.vue'),
    meta: {
      requireAuth: true,
      title: 'Tags'
    }
  },
  {
    path: '/tags/:id',
    name: 'view-tag',
    component: () => import(/* webpackChunkName: "ViewTag" */ '@/components/TagManagement/viewTag.vue'),
    meta: {
      requireAuth: true,
      title: 'Tag Info'
    }
  },
  {
    path: '/industries/:id',
    name: 'view-industry',
    component: () => import(/* webpackChunkName: "ViewIndustry" */ '@/components/IndustryManagement/viewIndustry.vue'),
    meta: {
      requireAuth: true,
      title: 'Industry Info'
    }
  },
  {
    path: '/landlords/:id',
    name: 'view-landlord',
    component: () => import(/* webpackChunkName: "ViewLandlord" */ '@/components/LandlordManagement/viewLandlord.vue'),
    meta: {
      requireAuth: true,
      title: 'Landlord Info'
    }
  },
 // /broker-properties
 {
  path: '/broker-properties',
  name: 'broker-properties',
  component: () => import(/* webpackChunkName: "BrokerProperties" */ '@/views/Property Pages/BrokerProperties.vue'),
  meta: {
    requireAuth: true,
    title: 'Broker Properties'
  }
},
{
  path: "/external-property",
  name: "externalProperty",
  component: () =>
    import(
      /* webpackChunkName: "ExternalPropertyPage" */ "@/views/ExternalProperty.vue"
    ),
  meta: {
    requireAuth: true,
    title: 'External Property'
  },
},
  {
    path: '/proposal-request',
    name: 'proposal-request',
    component: () => import(/* webpackChunkName: "ProposalRequestPage" */ '@/views/Property Proposal Pages/ProposalRequestPage.vue'),
    meta: {
      requireAuth: true,
      title: 'Proposal Request'
    }
  },
  {
    path: '/proposal-data',
    name: 'proposal-data',
    component: () => import(/* webpackChunkName: "ProposalDataPage" */ '@/views/Property Proposal Pages/ProposalDataPage.vue'),
    meta: {
      requireAuth: true,
      title: 'Proposal Data'
    }
  },
  {
    path: '/visit-schedule',
    name: 'visit-schedule',
    component: () => import(/* webpackChunkName: "VisitSchedule" */ '@/views/Property Visit Page/visitSchedulePage.vue'),
    meta: {
      requireAuth: true,
      title: 'Visit Schedule'
    }
  },
  {
    path: '/jobs/edit/:id',
    name: 'edit-jobs',
    component: () => import(/* webpackChunkName: "editJobs" */ '@/components/JobManagement/editJob.vue'),

    meta: {
      requireAuth: true,
      title: 'Edit Job'
    }
  },
  {
    path: '/jobs/add',
    name: 'add-job',
    component: () => import(/* webpackChunkName: "addJob" */ '@/components/JobManagement/addJob.vue'),
    meta: {
      requireAuth: true,
      title: 'Add Job'
    }
  },
  {
    path: '/brokers/:id',
    name: 'view-broker',
    component: () => import(/* webpackChunkName: "ViewBroker" */ '@/components//BrokerManagement/viewBroker.vue'),
    meta: {
      requireAuth: true,
      title: 'Broker Info'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "UserPage" */ '@/views/Mangement Pages/UsersPage.vue'),
    meta: {
      requireAuth: true,
      title: 'Users'
    }
  },
  {
    path: '/users/:id',
    name: 'view-user',
    component: () => import(/* webpackChunkName: "ViewUser" */ '@/components/UserManagement/viewUser.vue'),
    meta: {
      requireAuth: true,
      title: 'User Info'
    }
  },
  {
    path: "/agprop-directory",
    name: "agprop-directory",
    component: () =>
      import(/* webpackChunkName: "UsersDirectoryPage" */ "../views/UserDirectoryPage.vue"),
    meta: {
      requireAuth: true,
      title: 'agprop Directory'
    },
  },

  {
    path: "/roles",
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "RolePage" */ "../views/Mangement Pages/RolesPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Roles'
    },
  },
  {
    path: "/offices",
    name: "office",
    component: () =>
      import(/* webpackChunkName: "OfficePage" */ "../views/Mangement Pages/OfficePage.vue"),
    meta: {
      requireAuth: true,
      title: 'Offices'
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () =>
      import(/* webpackChunkName: "PermissionsPage" */ "../views/Mangement Pages/PermissionsPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Permisssion'
    },
  },
  {
    path: "/brands",
    name: "brands",
    component: () =>
      import(/* webpackChunkName: "BrandPage" */ "../views/Brands Pages/BrandsPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Brands'
    },
  },
  {
    path: "/industries",
    name: "industries",
    component: () =>
      import(/* webpackChunkName: "IndustryPage" */ "../views/Configuration Pages/IndustryPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Industries'
    },
  },
  {
    path: "/brands/:id",
    name: "view-brand",
    component: () =>
      import(/* webpackChunkName: "View-Brand" */ "../components/BrandManagement/viewBrand.vue"),
    meta: {
      requireAuth: true,
      title: 'Brand Info'
    },
  },
  {
    path: "/all-brand-requirements",
    name: "all-brands",
    component: () =>
      import(/* webpackChunkName: "AllBrandRequirementPage" */ "../views/Brands Pages/allBrandRequirementPage.vue"),
    meta: {
      requireAuth: true,
      title: 'All Brand Requirements'
    },
  },

  {
    path: "/add-property",
    name: "add property",
    component: () =>
      import(/* webpackChunkName: "AddPropertiesPage" */ "../components/PropertyManagement/AddPropertyMainPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Add Property'
    },
  },
  
  {
    path: "/properties",
    name: "properties",
    component: () =>
      import(/* webpackChunkName: "PropertyPage" */ "../views/Property Pages/PropertiesPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Properties'
    },
  },

  {
    path: "/property/edit/:id",
    name: "edit-properties",
    component: () =>
      import(/* webpackChunkName: "EditPropertyPage" */ "../components/PropertyManagement/EditPropertyMainPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Edit Property'
    },
  },
  {
    path: "/properties/:id",
    name: "view-property",
    component: () =>
      import(/* webpackChunkName: "ViewProperty" */ "../components/PropertyManagement/viewProperty.vue"),
    meta: {
      requireAuth: true,
      title: 'View Property'
    },
  },
  {
    path: "/brand-requirement/:id",
    name: "view-brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "View-brandRequirement" */ "../components/AllBrandRequirementManagement/viewBrandRequirement.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'View Brand Requirement'
    },
  },

  {
    path: "/brand-contacts",
    name: "brand-contacts",
    component: () =>
      import(/* webpackChunkName: "BrandContactPage" */ "../views/Brands Pages/BrandContactPage.vue"),
    meta: {
      requireAuth: true,
      title: 'Brand Contacts'
    },
  },
  {
    path: "/brokers",
    name: "brokers-management",
    component: () =>
      import(
        /* webpackChunkName: "BrokerPage" */ "../views/Property Assets Pages/BrokersPage.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'Brokers'
    },
  },
  {
    path: "/jobs",
    name: "Job-management",
    component: () =>
      import(
        /* webpackChunkName: "JobPage" */ "../views/Careers Pages/JobPage.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'Jobs'
    },
  },
  {
    path: "/view-job-profile/:id",
    name: "View-job",
    component: () =>
      import(
        /* webpackChunkName: "viewJobProfile" */ "../components/JobManagement/viewJobs.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'View Job'
    },
  },
  {
    path: "/job-applicants",
    name: "View-job-applicants",
    component: () =>
      import(
        /* webpackChunkName: "viewJobApplicants" */ "../views/Careers Pages/JobApplicantsPage.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'Job Applicants'
    },
  },
  {
    path: '/agprop-ai',
    name: 'agprop-ai',
    component: () => import(/* webpackChunkName: "AgpropAiPage" */ '../views/AgpropAiPage.vue'),
    meta: {
      requireAuth: true,
      title: 'agprop ai'
    }
  },
  {
    path: "/website-queries",
    name: "website-queries",
    component: () =>
      import(
        /* webpackChunkName: "smart-search" */ "../views/WebsiteQueriesPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Smart Search",
    },
  },
  {
    path: "/smart-search",
    name: "smart-search",
    component: () =>
      import(
        /* webpackChunkName: "smart-search" */ "../views/SmartSearchPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Smart Search",
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  localStorage.setItem("routeDetail", JSON.stringify({ to: to.path, from: from.path }));
  let isAuth = localStorage.getItem('accessToken')
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!isAuth) {
      next('/login')
      return
    } else {
      document.title = to.meta.title+ ' - agprop'
      next()
      return
    }
  } else {
    document.title = to.meta.title+ ' - agprop'
    next()
    return
  }
})


export default router
