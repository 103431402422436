import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
const state = {
  summeryData: [],
};

const getters = {
  getsummeryData: (state) => state.summeryData,
};

const mutations = {
  setsummeryData: (state, payload) => (state.summeryData = payload),
  doNothing: (state) => state,
};

const actions = {
  /**
   * @description Get All summeryData
   * @param {Number} data - summeryData Object
   * @returns Promise
   */
  async fetchAllSummeryData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.summary.getAllSummary)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setsummeryData", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
